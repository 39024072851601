export default {
  login: {
    login: "登 录",
    email: "电子邮箱",
    mobile: "手机号码",
    mobile2: "手机号码",
    password: "密码",
    errMsg1: "请输入电子邮箱和密码",
    errMsg2: "请输入手机号码和密码",
    errMsg3: "您的帐户不在此地区",
    errMsg4: "您的用户名或密码不正确",
    errMsg5: "您的输入手机号码或密码不正确",
    errMsg6: "您的注册还未完成，请重新登记及完成付款步骤",
    forgotpw: "忘记密码？",
    register: "注 册",
    txt: "还不是御宝会员？查看",
    txt_2: "会员礼遇",
  },
  header: {
    home: "首页",
    eStore: "电子商城",
    PRivileges: "会员礼遇",
    register: "注册",
    highlights: "亮点",
    faq: "常见问题",
    mobileApp: "手机应用程序",
    tnp: "条款和隐私",
    friendReferral: "朋友推荐",
    profile: "会员档案",
  },
  footer: {
    aboutUs: "关于御宝",
    eStore: "电子商城",
    faq: "常见问题",
    tnp: "条款与隐私",
    pr: "参与分店",
    profile: "会员档案",
    contactUs: "联系我们",
    copyright: "2025©御宝饮食集团版权所有",
  },
  signUp: {
    signUp: "注册会员",
    title: "注册御宝忠诚计划会员",
    mustFill: "“*” 为必填项",
    step1: "账户资料",
    step2: "安全信息",
    step3: "信息订阅",
    step4: "推荐人",
    salutation: "称谓",
    salutation_opt1: "请选择您的称谓",
    salutation_opt2: "先生",
    salutation_opt3: "太太",
    salutation_opt4: "女士",
    salutation_opt5: "小姐",
    salutation_opt6: "博士",
    salutation_errMsg: "请选择称谓",
    firstname: "名字",
    firstname_errMsg: "请输入您的名字",
    lastname: "姓氏",
    lastname_errMsg: "请输入您的姓氏",
    birth: "出生日期",
    birth_errMsg: "请选择出生日期",
    birth_errMsg2: "您必须年满18岁",
    mobile: "手机号码",
    mobile_errMsg: "请输入有效电话",
    mobile_errMsg2: "手机号码已注册",
    emailAddress: "电子邮箱",
    emailAddress_errMsg: "请输入有效的电子邮箱",
    emailAddress_errMsg2: "电子邮箱已注册",
    confirmEmailAddress: "电子邮箱确认",
    confirmEmailAddress_errMsg: "请确认电子邮箱",
    gender: "性别",
    gender_opt1: "请选择您的性别",
    gender_opt2: "男",
    gender_opt3: "女",
    gender_errMsg: "请选择性别",
    language_preference: "语言选项",
    language_preference_opt1: "请选择您的语言选项",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_errMsg: "请选择语言选项",
    password_format: "密码至少为8个字符，其中需包含大小字母、数字和一个特殊字符 (?!@$%&*)",
    password: "*密码",
    password_errMsg: "请输入密码",
    confirm_password: "*密码确认",
    confirm_password_errMsg: "请再次输入密码",
    step3_consent_opt: "我承认我已经阅读并同意此处列出的<link>条款和条件<link>和隐私政策。",
    tnc_errMsg: "请接受条款和条件以继续下一步",
    step3_title: "通过勾选以下的方框，我同意御宝餐饮集团有限公司及其子公司（“ITRG”）收集、使用、储存或披露与我的御宝会员身份相关的个人信息（“个人信息”），根据隐私声明，以便ITRG将我的个人信息共享至其附属公司、关联公司或其他第三方，从而使我能够享受御宝俱乐部会员资格的权益，包括源于ITRG与新加坡内外服务提供商之间关系而产生的权益",
    step3_q: "我希望收到来自ITRG的营销/推广信息与材料。",
    step3_q_opt1: "通过电子邮件",
    step3_q_opt2: "通过电话",
    step4_q_err: "请选择如何得知御宝忠诚计划",
    step4_q: "您是如何得知御宝忠诚计划？",
    step4_q_opt1: "请选择",
    step4_q_opt2: "大世界, Bar + Grill",
    step4_q_opt3: "滨海湾金沙, 御宝轩",
    step4_q_opt4: "百利宫, 御宝至尊烤鸭店",
    step4_q_opt5: "亚洲广场, 御宝至尊烤鸭店",
    step4_q_opt6: "爱雍·乌节, 御宝阁潮州酒家​",
    step4_q_opt7: "滨海湾金沙, 御宝阁潮州酒家​",
    step4_q_opt8: "高岛屋购物中心, 御园​",
    step4_q_opt9: "大世界, 御宝轩",
    step4_q_opt10: "大世界, 御宝火锅店",
    step4_q_opt11: "爱雍·乌节, 御宝火锅店",
    step4_q_opt12: "111 索美赛, 御宝火锅店",
    step4_q_opt13: "百利宫, 宝一点心",
    step4_q_opt14: "百汇广场, 宝一点心",
    step4_q_opt15: "来福士城, 宝一点心",
    step4_q_opt16: "口碑介绍",
    step4_q_opt17: "社交媒体",
    step4_q_opt18: "其他",
    step4_q_opt19: "星耀樟宜，御宝至尊烤鸭店",
    step4_q_opt20: "星耀樟宜，宝一点心",
    step4_q_opt21: "爱雍·乌节, 御宝淮扬料理",
    promo_code: "优惠码",
    promo_code_input_msg: "请输入有效的促销代码（如有）",
    promo_code_err_msg: "请输入有效的促销代码",
    promo_code_re_msg: "促销代码有效!",
    total: "总共：",
    refresh: "刷新",
    captcha: "验证码",
    captcha_errMsg: "验证码不正确",
    cancel: "取消",
    next: "下一步",
    back: "上一步",
    submitForm: "立即付款",
  },
  aboutUs: {
    aboutUs: "关于御宝",
    txt1: "御宝饮食集团",
    txt2: "御宝饮食集团创建于 2004 年。中华美食源远流长，御宝尊崇博大精深的美食文化，矢志继承这宝贵遗产。因此御宝一直坚持以道地的烹饪手法，为食客奉上美味丰盛的佳肴。加之御宝以服务至上，细致贴心，宗旨是提供舒适的用餐体验。",
    txt3: "迄今为此，御宝集团旗下囊括多过二十间店铺，各色美食，应有尽有。在经验领导及顾客支持下，御宝更荣获超過五十不同獎項包括：米其林指南上海两颗星 （2017 - 2023） 御宝轩 （益丰）, 米其林指南广州两颗星 （2020 - 2022）,  一颗星（2019） 御宝轩 （天汇广场）,米其林指南新加坡一颗星（2017 - 2019，2021-2022） 御宝阁潮州酒家 （爱雍·乌节）,  米其林指南香港澳门一颗星（2018 - 2022） 御宝轩 (尖沙咀), 米其林指南广州一颗星（2021- 2022） 御宝阁潮州酒家 （太古汇）, 米其林指南广州米其林餐盘获荣誉奖（2018， 2020）, 米其林指南法国米其林餐盘获荣誉奖（2020）, 米其林指南新加坡米其林餐盘获荣誉奖（2018，2019）,  米其林指南英国和爱尔兰米其林餐盘获荣誉奖（2019）, 美团点评黑珍珠餐厅指南二钻(2018), 全球最佳中餐厅，由庄布忠颁发, 亚洲 “50家最佳” 餐厅等诸多美誉（2013 - 2015）。",
    txt4: "御宝在新加坡，上海，广州，香港，仁川，伦敦和巴黎设有分店。",
  },
  faqs: {
    faqs: "须知",
    general: "一般",
    general_1: "什么是御宝奖励计划？",
    general_1_1: "御宝奖励计划是一个无卡显忠诚项目，无论什么时候，只要在参与的国内外任何御宝分店用餐，会员便可以享受独家优惠和酬宾活动。",
    general_2: "如何成为会员？",
    general_2_1: "您可以在 members.imperialtreasure.com 在线注册。或者，您也可以在任何参与的御宝分店注册。",
    general_3: "在新加坡有哪些参与的分店？",
    general_3_1: "Imperial Treasure Bar + Grill",
    general_3_1_1: "大世界, #02-111 | 电话：(+65) 6732 2232",
    general_3_2: "御宝轩 Imperial Treasure Fine Chinese Cuisine",
    general_3_2_1: "滨海湾金沙, 购物商城, #02-04 | 电话：(+65) 6688 7788",
    general_3_3: "御宝至尊烤鸭店 Imperial Treasure Super Peking Duck",
    general_3_3_1: "百利宫, ＃05-42 | 电话：(+65) 6732 7838",
    general_3_3_2: "亚洲广场 1座, #02-08 | 电话：(+65) 6636 1868",
    general_3_3_3: "星耀樟宜, #01-219 | 电话：(+65) 6908 6689",
    general_3_4: "御宝阁潮州酒家 Imperial Treasure Fine Teochew Cuisine",
    general_3_4_1: "爱雍·乌节, ＃03-05 | 电话：(+65) 6736 2118",
    general_3_4_2: "滨海湾金沙, 购物商城, #01-26 | 电话：(+65) 6688 7333",
    general_3_5: "御园 Imperial Treasure Fine Shanghai Cuisine",
    general_3_5_1: "高岛屋购物中心, ＃04-22 | 电话：(+65) 6836 6909",
    general_3_6: "御宝轩 Imperial Treasure Cantonese Cuisine ",
    general_3_6_1: "大世界, #02-111 | 电话：(+65) 6732 2232",
    general_3_7: "御宝火锅店 Imperial Treasure Steamboat Restaurant",
    general_3_7_1: "爱雍·乌节, #04-12A | 电话：(+65) 6636 9339",
    general_3_7_2: "大世界, #01-140 | 电话：(+65) 6235 6008",
    general_3_7_3: "111 索美赛, #02-14 | 电话：(+65) 6235 3882",
    general_3_8: "宝 一点心 Treasures Yi Dian Xin",
    general_3_8_1: "百利宫, #B1-08 | 电话：(+65) 6262 3662",
    general_3_8_2: "Parkway Parade, #02-14 | 电话：(+65) 6247 9218",
    general_3_8_3: "来福士城, #B1-37 | 电话：(+65) 6262 1662",
    general_3_8_4: "星耀樟宜, #01-221 | 电话：(+65) 6908 3668",
    general_3_9: "（*御宝饮食集团保留更改参与分店的权利，恕不另行通知。）",
    general_3_10: "御宝淮扬料理 Imperial Treasure Huaiyang Cuisine",
    general_3_10_1: "爱雍·乌节, #04-12A | 电话 : (+65) 6636 9339",

    general_4: "成为会员的注册费用是多少？ ",
    general_4_1: "自2025年4月1日期， 会员注册费为S$38。",
    general_5: "如何支付会员费？",
    general_5_1: "对于在参与御宝分店注册，可以直接在柜台付款。对于在线注册，可以使用Visa，万事达卡和 eNets进行付款。",
    general_6: "会员的有效期是多久？",
    general_6_1: "会员有效期为自注册月份起的1年内。例如：如果注册于2019年8月17日，则会员资格将于2020年8月31日到期。",
    general_7: "在哪里可以检查我的会员到期日？",
    general_7_1: "您可以登录会员专属网页以检查会员资格的到期日。",
    general_8: "申请成为会员的最低年龄是多少？",
    general_8_1: "顾客需要年满18岁才能申请成为会员。",
    general_9: "如何更新我的个人资料？",
    general_9_1: "如需更新或查看您的个人资料，请通过会员专属网页 members.imperialtreasure.com 登录您的帐户，然后单击“个人资料”。如需更新您的出生日期， 请发送电子邮件至 <link>sg.club@imperialtreasure.com<link> 。",
    general_10: "如何检查我的积分，电子礼券和其它奖励？",
    general_10_1: "您可以登录会员专属网页 members.imperialtreasure.com 查看您的会员优惠和积分余额。",
    general_11: "如果我忘记了密码怎么办？",
    general_11_1: "如需要重置密码，请登录会员专属网页 members.imperialtreasure.com 并单击“忘记密码”，按照屏幕上的提示重置密码。",
    general_12: "如果有关于我的会员帐户的疑问，该怎么办？",
    general_12_1: "如果您对您的会员帐户有任何疑问，请发送电子邮件至 <link>sg.club@imperialtreasure.com<link> 。",
    general_13: "如何终止我的会员资格？",
    general_13_1: "会员可以选择在失效日期之前的任何时间通过书信电邮至 <link>sg.club@imperialtreasure.com<link> 联系我们以终止其会员资格。请注意，在终止时将不会退款和/或提前发放任何未使用的电子礼券。另一种方式是让您的会员资格自动失效，所有积分和/或未使用的电子礼券将被视为放弃使用。",

    tier: "会员等级",
    tier_1: "会员级别有哪些？",
    tier_1_1: "会员级别分三种：银，金和钻石。",
    tier_2: "如何成为银，金或钻石会员？",
    tier_2_1: "成为银会员，在线注册或者在任何参与御宝分店以S$38注册。",
    tier_2_2: "要成为金会员，会员需要在会员年份内累积有效消费达S$10,888 *或以上。",
    tier_2_3: "要成为钻石会员，会员需要在会员年度内累积有效消费达到S$28,888*或以上。",
    tier_2_4: "*有效消费金额不包含服务费和消费税",

    tier_3: "如何保留我的会员资格？",
    tier_3_1: "要维持银会员资格，只需支付S$20的更新费。",
    tier_3_2: "要维持金会员资格，只需在会员年份内累积有效消费达S$10,888*或以上，即可续保会员资格。",
    tier_3_3: "要保持钻石会员资格，只需在会员年份内累积有效消费S$28,888*或以上，即可续保会员资格。",
    tier_3_4: "*有效消费金额不包含服务费和消费税。",

    membership: "会员优惠和奖励",
    membership_1: "注册成为会员可以享受什么优惠？",
    membership_1_1: "自2025年4月1日期， 一旦完成注册，你将获得以下优惠：",
    membership_1_1_1: "1 x S$50注册电子礼券（有效期为3个月）",
    membership_1_1_2: "1 x S$100 生日电子礼券 （单张消费满 280 新元，不包括服务费和消费税） [礼劵分发日期为月初一（01）号，若生日当月注册，礼劵将在明年分发。仅在会员的生日月份内有效使用。每个会员期仅限分发一张]",
    membership_1_1_3: "2 x 火锅极品电子礼券 (日本和牛以及老虎斑, 各一份;有效期为 12 个月, 仅限堂食, 适用于御宝火锅店)",
    
    membership_2: "会员优惠有哪些？",
    membership_2_1_1: "优惠类型",
    membership_2_1_2: "银",
    membership_2_1_3: "金",
    membership_2_1_4: "钻石",

    membership_2_2_1: "赚取积分",
    membership_2_2_2: "享有",
    membership_2_2_3: "享有",
    membership_2_2_4: "享有",

    membership_2_3_1: "S$50电子礼券兑换",
    membership_2_3_2: "享有",
    membership_2_3_3: "享有",
    membership_2_3_4: "享有",
    
    membership_2_4_1: "S$38注册礼遇",

    membership_2_5_1: "S$50注册电子礼券",
    membership_2_5_2: "享有",
    membership_2_5_3: "-",
    membership_2_5_4: "-",

    membership_2_6_1: "S$100生日电子礼券",
    membership_2_6_2: "享有",
    membership_2_6_3: "享有",
    membership_2_6_4: "享有",

    membership_2_7_1: "日本和牛电子礼券(一份)",
    membership_2_7_2: "享有",
    membership_2_7_3: "-",
    membership_2_7_4: "-",

    membership_2_8_1: "老虎斑电子礼券(一份)",
    membership_2_8_2: "享有",
    membership_2_8_3: "-",
    membership_2_8_4: "-",

    membership_2_9_1: "赠送礼品：",

    membership_2_10_1: "北京烤鸭（一只）",
    membership_2_10_2: "-",
    membership_2_10_3: "享有",
    membership_2_10_4: "享有",

    membership_2_11_1: "烤乳猪（一只）",
    membership_2_11_2: "-",
    membership_2_11_3: "-",
    membership_2_11_4: "享有",

    membership_2_12_1: "红酒（一瓶）",
    membership_2_12_2: "-",
    membership_2_12_3: "享有",
    membership_2_12_4: "享有",

    membership_2_13_1: "香槟（一瓶）",
    membership_2_13_2: "-",
    membership_2_13_3: "-",
    membership_2_13_4: "享有",

    membership_2_14_1: "免除开瓶电子礼券",
    membership_2_14_2: "-",
    membership_2_14_3: "享有",
    membership_2_14_4: "享有",

    membership_2_15_1: "无限次火锅汤底电子礼券",
    membership_2_15_2: "-",
    membership_2_15_3: "享有",
    membership_2_15_4: "享有",

    membership_2_16_1: "无限次免除开瓶电子礼券",
    membership_2_16_2: "-",
    membership_2_16_3: "-",
    membership_2_16_4: "享有",

    membership_2_17_1: "新年糕点（季节性）",
    membership_2_17_2: "-",
    membership_2_17_3: "一份",
    membership_2_17_4: "两份",

    membership_2_18_1: "端午粽子（季节性）",
    membership_2_18_2: "-",
    membership_2_18_3: "一份",
    membership_2_18_4: "两份",

    membership_2_19_1: "中秋月饼（季节性）",
    membership_2_19_2: "-",
    membership_2_19_3: "一份",
    membership_2_19_4: "两份",


    membership_2_20_1: "奖励电子礼券",

    membership_2_21_1: "奖励电子礼券* *需累积消费",
    membership_2_21_2: "-",
    membership_2_21_3: "S$500",
    membership_2_21_4: "S$1500",

    membership_2_22_1: "S$20更新礼遇",

    membership_2_23_1: "S$28更新电子礼券",
    membership_2_23_2: "享有",
    membership_2_23_3: "-",
    membership_2_23_4: "-",

    membership_2_24_1: "S$100生日电子礼券",
    membership_2_24_2: "享有",
    membership_2_24_3: "享有",
    membership_2_24_4: "享有",

    membership_2_25_1: "S$30火锅汤底电子礼券",
    membership_2_25_2: "享有",
    membership_2_25_3: "享有",
    membership_2_25_4: "享有",

    membership_3: "如何知道我的会员优惠是否到期？",
    membership_3_1: "要检查各项优惠的到期日期，只需登录会员专属网页并查看“礼券”部分下的详细信息。",

    membership_4: "可以一起使用电子优惠礼券吗？",
    membership_4_1_1: "电子礼券",
    membership_4_1_2: "S$50注册 /\nS$50更新 /\nS$28更新",
    membership_4_1_3: "S$100 生日 ",
    membership_4_1_4: "S$50 /\nS$500奖励 /\nS$1500 奖励",
    membership_4_1_5: "赠送礼品 （例如：烤鸭，\n烤乳猪）",
    membership_4_2_1: "S$50注册 / S$50更新 / S$28更新",
    membership_4_2_2: "不可以",
    membership_4_2_3: "不可以",
    membership_4_2_4: "不可以",
    membership_4_2_5: "可以",
    membership_4_3_1: "S$100生日",
    membership_4_3_2: "不可以",
    membership_4_3_3: "不可以",
    membership_4_3_4: "可以",
    membership_4_3_5: "可以",
    membership_4_4_1: "S$50 / S$500奖励 / S$1500 奖励",
    membership_4_4_2: "不可以",
    membership_4_4_3: "可以",
    membership_4_4_4: "可以",
    membership_4_4_5: "可以",
    membership_4_5_1: "赠送礼品 （例如：烤鸭，烤乳猪）",
    membership_4_5_2: "可以",
    membership_4_5_3: "可以",
    membership_4_5_4: "可以",
    membership_4_5_5: "可以",

    membership_5: "请点击<link>这里<link>以查阅电子礼券的条款与条件。",
    
    membership_6: "为什么须要注册我的个人资料？",
    membership_6_1: "注册您的个人资料，是作为您的生日电子礼券验证用途。 此外，您将收到相关的会员促销、奖励、活动邀请和其它信息。",

    membership_7: "如何证明自己是会员？",
    membership_7_1: "只需在参与御宝分店用餐时，向我们的服务人员提供您的手机号码便可。",

    membership_8: "如何领取赠送礼品？",
    membership_8_1: "红酒礼品能在指定参与御宝分店领取除了宝 一点心。顾客将收到电子邮件通知，以便在参与分店领取礼品。",
    membership_8_2: "香槟礼品能在指定参与御宝分店领取除了宝 一点心。顾客将收到电子邮件通知，以便在参与分店领取礼品。",
    membership_8_3: "北京烤鸭礼品能在参与御宝轩以及御宝至尊烤鸭店领取。须提前24小时预订。",
    membership_8_4: "烤乳猪礼品能在御宝轩, 御宝至尊烤鸭店 (百利宫)，御宝至尊烤鸭店 (亚洲广场) 和御宝阁潮州酒家餐馆兑换。须提前24小时预订。",
    membership_8_5: "季节性礼品 （年糕，粽子，月饼）只能在相关节日时领取。符合享受赠送礼品的会员将在节日来临之际收到电子邮件通知，以便在参与分店领取礼品。",

    membership_9: "作为一个企业客户，公司折扣能不能与会员优惠并用？",
    membership_9_1: "不可以。公司折扣不能与会员优惠一起使用。企业客户只能在公司折扣或会员优惠二者选其一。",
    membership_10: "会员能不能在海外的御宝分店享有优惠？",
    membership_10_1: "自 2024 年 3 月 7 日起，会员能在位于国外任何御宝分店用餐时将能够赚取 i-dollar (i$)。",

    membership_10_2: "参与国外分店：",

    membership_10_2_1: "上海",
    membership_10_2_2: "御宝轩 (益丰•外滩源)",
    membership_10_2_3: "北京东路99号益丰·外滩源四层402-403, 上海市黄浦区",

    membership_10_2_4: "广州",
    membership_10_2_5: "御宝轩 (天汇广场)",
    membership_10_2_6: "兴民路222号天汇广场igc五层L514B商铺, 广州市天河区",
    membership_10_2_7: "御宝阁潮州酒家 (太古汇商场)",
    membership_10_2_8: "天河路383号太古汇商场三层L307铺, 广州市天河区",

    membership_10_2_9: "香港",
    membership_10_2_10: "御宝轩 (北京道1号）",
    membership_10_2_11: "北京道1号10楼, 香港九龙尖沙咀",
    membership_10_2_12: "御宝轩 (皇冠假日)",
    membership_10_2_13: "礼顿道8号, 皇冠假日酒店2楼, 香港铜锣湾",

    membership_10_2_14: "仁川",
    membership_10_2_15: "御宝轩 (百乐达斯城）",
    membership_10_2_16: "韩国仁川广域市中区永宗海岸南路321街186, 百乐达斯城",
    membership_10_2_17: "御宝点心火锅店 (百乐达斯城广场)",
    membership_10_2_18: "韩国仁川广域市中区永宗海岸南路321街186, 百乐达斯城广场",

    membership_10_2_19: "伦敦",
    membership_10_2_20: "御宝轩",
    membership_10_2_21: "9 Waterloo Place, 圣詹姆斯, 伦敦, SW1Y 4BE",

    membership_10_2_22: "巴黎",
    membership_10_2_23: "御宝轩",
    membership_10_2_24: "44 rue de Bassano, 75008 巴黎, 法国 (巴黎香榭丽舍大街拉克莱夫酒店内)",

    membership_11: "会员资格是否可以转让？",
    membership_11_1: "会员资格不可转让。",

    point: "赚取与兑换积分",
    point_2: "如何赚取i-dollar（i $）？",
    point_2_1: "在<link>国内外<link>任何御宝分店每消费 S$10* 可赚取 i$1。会员必须在场并交付账单以赚取i-dollar。i$赚取必须在买单时进行积分赚取。之前或过期的单据一律不被接受积分赚取。一旦会员资格到期，i-dollar（i $）也将过期。未使用的i-dollar（i $）将享有3个月的宽限期。会员必须在宽限期成功转换为电子礼券，任何未使用的i-dollar将被作废。（*不包括服务费，消费税, 电子礼券兑换，购买或使用御宝餐券和季节产品）",
    point_3: "如何使用积分？",
    point_3_1: "每赚取 50积分(i$50)，会员将自动收到S$50*的电子礼券（只限会员有效期内使用）。 会员可在任何参与御宝分店用餐时使用电子礼券抵扣账单。会员必须在场并交付账单以兑换会员优惠。（*系统每天在规定的时间自动转换积分余额）",
    point_4: "可以在电子商店在线购物赚取和使用积分或电子礼券吗？",
    point_4_1: "不能，除非另有规定。",
    point_5: "我的i-dollar会过期吗？",
    point_5_1: "会的。i-dollar（i $）只在会员期间有效，一旦会员资格到期，i-dollar（i $）也将过期。未使用的i-dollar（i $）将享有3个月的宽限期。会员必须在宽限期成功转换为电子礼券，任何未使用的i-dollar将被作废。",
    point_6: "每次消费后，我的i-dollar会立即显示出来吗？",
    point_6_1: "会的。系统将即时显示赚取的i-dollar。除非在网络延迟导致的情况下，请允许些时段让系统显示所赚取的i-dollar。",
    point_7: "可以使用多个会员帐户赚取和兑换i-dollar吗？",
    point_7_1: "不可以。",
    point_8: "如果我不在场，我的家人或朋友可以使用我的会员优惠赚取或者兑换i-dollar吗？",
    point_8_1: "不可以。会员必须在场以享受会员优惠。",
    point_9: "可以通过 Grabfood、Deliveroo、Foodpanda 或 order.imperialtreasure.com 赚取i-dollar（i $）或使用电子礼券吗？",
    point_9_1: "不可以。",

    renewal: "会员续费",
    renewal_1: "如何为我的会员资格续费？",
    renewal_1_1: "会员可以从会籍有效期月份的第一天起，通过会员专属网页 members.imperialtreasure.com 进行续订。如果您的会员会籍已过期，您也可以在任何参与的分店续订会籍。",
    renewal_2: "会员资格续费是多少？",
    renewal_2_1: "自2025年4月1日期，续费为S$20。",
    renewal_3: "如何为我的金/钻石会员资格续费？",
    renewal_3_1: "只需在会员年份内累积有效消费达S$10,888*或以上（金会员）或S$28,888*或以上（钻石会员），即可在有效期后自动续保会员资格。",
    renewal_4: "续费成为会员可以享受什么优惠？",
    renewal_4_1_1: "自2025年4月1日期，成功续费的会员将在新的周期享有以下优惠：",
    renewal_4_1: "1 x S$28 更新电子礼券 （有效期为会员更新身份的3个月内)",
    renewal_4_2: "3 x S$30 火锅汤底电子礼券 (有效期12个月)",
    renewal_4_3_1: "会员将在生日月初 1号获得：",
    renewal_4_3: "1 x S$100 生日电子礼券 （礼券分发日期为月初 1号，若生日当月注册礼券将在明年分发。每周期仅限一张（01）电子礼券）",
    renewal_5: "可以在会员资格有效日期前更新会员会籍吗？",
    renewal_5_1: "会员可以从会籍有效期月份的第一天起，通过会员专属网页 members.imperialtreasure.com 进行续订, 新会员会籍周期将从原来的有效期日开始计算。\n\n请注意, 新的会员会籍到期日期仅会在当前会员周期结束后反映在您的账户中, 更新电子礼券也将在新会员周期开始时发放。",

  },
  tnc: {
    title: "条款和隐私",
    membership: "会员条款和隐私",
    membership_1_1: "1.1 Introduction",
    membership_1_1_1:
      'Imperial Treasure Restaurant Group Pte Ltd (“Imperial Treasure”) would like to offer our privileged customers news and information regarding our products and services, including promotions, special offers and discounts.  We are offering you the opportunity to become a member of the Imperial Treasure Club (a "Member") to enjoy special privileges that Imperial Treasure may offer from time to time.',
    membership_1_2: "1.2 Collection and use of personal data",
    membership_1_2_1:
      "If you become a Member, you will need to provide Imperial Treasure with certain personal information (including your name and contact details such as email address and phone number).  You will need to provide us with your mobile number for us to identify you. Your mobile number will be your username or unique identifier as a Member. Any personal data held by us about you will be held in accordance with our Privacy Notice.",
    membership_1_3: "1.3 Membership Tiers",
    membership_1_3_1: "1.3.1 Membership registration, i-dollars, and Cash e-Vouchers",
    membership_1_3_1_1:
      "Where you sign-up to become a Member, you will be required to provide your mobile number, among other information we may require from you, in order to sign up for the membership. This may be required online or by our restaurant staff depending on the circumstances.",
    membership_1_3_1_2: "Membership is strictly non-transferable and shall only be enjoyed by the Member in person.",
    membership_1_3_1_3:
      "As a Member, you earn one (01) i-dollar at the rate specified on the membership programme website [https://members.imperialtreasure.com/] for the country in which you signed up, when you spend at any participating Imperial Treasure restaurants (excluding service charge, GST, Cash e-vouchers used, and the purchase and use of Imperial Treasure gift vouchers). Please refer to the Member’s Portal for a list of participating Imperial Treasure restaurants. Member must be physically present at the point of dining and provide the registered mobile number to the service staff in order to earn i-dollars. Earning of i-dollars must be made at the point of transaction. Requests on i-dollar accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, Cash e-vouchers and i-dollar earned. I-dollars accrued shall expire upon expiry of the membership. Any i-dollar accrued is however granted a three (03) months grace period and must be converted to Cash e-Vouchers within this grace period, failing which such i-dollar shall be deemed forfeited. Transactions made via third party vendors are not eligible for earning of i-dollars.",
    membership_1_3_1_4:
      "Earned i-dollars are converted in the Member Portal into Imperial Treasure e-vouchers of a specified value (“Cash e-Vouchers”) at the rate specified on the membership programme website [https://members.imperialtreasure.com/] for the country in which you signed up. Each Cash e-Voucher is valid for twelve (12) months from date of issue and valid for use within the membership period only. The Cash e-Voucher can be used to offset the bill when dining at any participating Imperial Treasure restaurants, subject to Imperial Treasure’s sole discretion and any other terms and conditions stated at [https://members.imperialtreasure.com/]. Neither i-dollars nor Cash e-Vouchers have any cash value and cannot be converted or redeemed for cash.",
    membership_1_3_2: "1.3.2 Membership Tiers",
    membership_1_3_2_1:
      "Unless otherwise determined by Imperial Treasure in its sole discretion, Silver Tier status is conferred on Members when they first join the membership programme and is valid for the period specified on the membership programme website [https://members.imperialtreasure.com/] for the country in which you signed up. At the end of this period, if the Member has not been upgraded to the Gold Tier or Diamond Tier, the Member may be required to pay such renewal fee as may be prescribed by Imperial Treasure in its sole discretion on the membership website [https://members.imperialtreasure.com/] for the country in which you signed up. ",
    membership_1_3_2_2:
      "Gold Tier status is conferred on Members who spend at least the amount set out for Gold Tier on the membership programme website [https://members.imperialtreasure.com/] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Gold Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Gold Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Silver Tier status.",
    membership_1_3_2_3:
      "Diamond Tier status is conferred on Members who spend at least the amount set out for Diamond Tier on the membership programme website [https://members.imperialtreasure.com/] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Diamond Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Diamond Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Gold Tier or Silver Tier status accordingly.",
    membership_1_3_2_4:
      "Imperial Treasure may, in its sole discretion, alter, limit, or modify the Silver Tier, Gold Tier and/or Diamond Tier programme rules, regulations, benefits, eligibility for membership, or any other feature of the Silver Tier, Gold Tier and/or Diamond Tier programme and/or may terminate the Silver Tier, Gold Tier and/or Diamond Tier programme at any time in its sole discretion, by giving reasonable notice on the membership programme website and/or by email. Please check the membership programme website regularly for updates. ",
    membership_1_3_3: "1.3.3 Membership Tier Rewards",
    membership_1_3_3_1:
      "Each membership tier also entitles Members to certain rewards and privileges, which may include other e-vouchers, free gifts and/or other dining benefits (“Rewards”). The current tier privileges are as set out in the membership programme website [https://members.imperialtreasure.com/]. The validity and terms and conditions of such Rewards are as set out in [https://members.imperialtreasure.com/], which may be amended by Imperial Treasure at any time in its sole discretion, by giving reasonable notice on the membership programme website and/or by email. Rewards are always subject to availability. Imperial Treasure reserves the right to replace a Reward with another Reward of equivalent or similar value. Rewards do not have any cash value and cannot be converted or redeemed for cash.",
    membership_1_3_4: "1.3.4 Redemption of Rewards and Cash e-Vouchers",
    membership_1_3_4_1:
      "Member must be physically present (proof of identification required) at the point of dining to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made. Redemption of Rewards are subject to prevailing local law requirements.",
    membership_1_3_4_2:
      "Combining multiple membership accounts, combining bills or splitting the bills to earn i-dollars and/or redeem Rewards and membership privileges is strictly not allowed. Membership must be active at the point of redemption.",
    membership_1_4: "1.4 Amending personal data and terminating membership",
    membership_1_4_1:
      'You may amend your personal data or marketing preferences at any time via https://members.imperialtreasure.com (the "Member\'s Portal"). You will need to inform us in writing at sg.club@imperialtreasure.com should you choose to terminate your membership. Please allow a few working days for changes to take effect. Kindly be advised that there will be no refunds made and/or early issuance of any unused Cash e-vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and all balance i-dollars and/or unused Cash e-vouchers will be deemed forfeited',
    membership_1_5: "1.5 Termination and cancellation",
    membership_1_5_1: "We reserve the right to terminate the Member's membership if the membership account is found to be shared with another non-Member.",
    membership_1_5_2:
      "We reserve the right to terminate your membership, in our absolute discretion, should you abuse any of the conditions and privileges bestowed on you as a Member.",
    membership_2: "2. Law and forum",
    membership_2_1:
      "These Terms, including all policies and additional terms referenced herein (“Membership Agreement”), are governed by and construed in accordance with the laws of Singapore.",
    membership_2_2:
      "Any dispute or claim arising out of or in connection with this Membership Agreement, (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of Singapore courts.",
    membership_3: "3. Consent",
    membership_3_1:
      "I would like to join the Imperial Treasure Membership club and I consent to the collection, holding, use and disclosure of my personal data in accordance with your Privacy Notice. If I provide Imperial Treasure with any personal data relating to a third party, by submitting such information, I represent and warrant that I have obtained the consent of the third party for Imperial Treasure to collect, use and disclose their personal data in accordance with this Privacy Notice.",
    privacy: "Privacy Notice",
    privacy_1: "1. INTRODUCTION",
    privacy_1_1:
      'Imperial Treasure Restaurant Group Pte Ltd, Imperial Treasure Cantonese Cuisine Pte Ltd, Imperial Treasure Noodle House Pte Ltd, Imperial Treasure Fine Dining Restaurant Pte Ltd, Multirich F&B Management Pte Ltd, Imperial Treasure Windows of Hong Kong Pte Ltd and Imperial Treasure Steamboat Pte Ltd (collectively referred as "Imperial Treasure", "us", "we") is committed to protecting the privacy of visitors to this website and our mobile application (the "Website"). This Privacy Notice sets out how Imperial Treasure collects, uses or discloses personal data that you have provided to us through the Website. By visiting the Website, you are accepting and consenting to the collection, use and disclosure of personal data described in this Privacy Notice. If you do not agree with this Privacy Notice, please do not use the Website. This Privacy Notice does not apply to other websites to which we link ("Third Party Website" or "Third Party Websites"). ',
    privacy_1_2:
      "This Privacy Notice also describes your data protection rights, including a right to object to some of the processing which Imperial Treasure carries out. More information about your rights, and how to exercise them, is set out in the following section: “8. What rights do I have”.",
    privacy_1_3:
      'Maintaining the privacy of our customers’ information is important to us. We do not disclose rent or sell our customers’ personal data to third parties (except as described in "5. Disclosures of personal data to third parties").',
    privacy_2: "2. COLLECTION AND USE OF PERSONAL DATA",
    privacy_2_1: "We collect the following personal data when you register as a user of the Website and make purchases through the Website:",
    privacy_2_1_1: "email address",
    privacy_2_1_2: "your name, address and phone number (including delivery addresses if different);",
    privacy_2_1_3: "billing address, including your name, address and phone number; and",
    privacy_2_1_4:
      "any other personal data provided to us when you submit any comment, question or feedback to us, request services from us, or disclose your personal data to us in any way.",
    privacy_2_2: "We may collect, use and disclose your personal data for the following purposes:",
    privacy_2_2_1: "To fulfil a contract, or take steps linked to a contract: this is relevant where you make a purchase from us. This includes to:",
    privacy_2_2_1_1: "fulfil your order and deliver it to you;",
    privacy_2_2_1_2: "facilitate and track your purchases from us (including in our restaurants or via our websites); and",
    privacy_2_2_1_3: "provide you with service support.",
    privacy_2_2_2: "As required by Imperial Treasure to conduct our business and pursue our legitimate interest, in particular:",
    privacy_2_2_2_1: "to facilitate your registration as a user of the Website;",
    privacy_2_2_2_2: "to use information you provide to provide products and services you have requested;",
    privacy_2_2_2_3:
      "(if you use a card payment for any purchase on the Website) we use third parties to check the validity of the sort code, account number and card number you submit in order to prevent fraud; and;",
    privacy_2_2_2_4: "to respond to comments, queries and feedback.",
    privacy_2_2_3: "Where you give us consent:",
    privacy_2_2_3_1:
      "we provide you with marketing and promotional material and other information about our products (including promotions and special offers), as well as other products and services provided by our affiliates and partners; and",
    privacy_2_2_3_2: "on other occasions where we ask you for consent, we will use the personal data for the purposes which we explain at that time.",
    privacy_2_2_4:
      "For purposes which are required by law: to comply with legal and regulatory obligations, carry out internal audits or investigations, prevent fraud or other unlawful activities, or ensure the safety and security of you and us.",
    privacy_2_2_5: "For purposes that are reasonably necessary or ancillary to the abovementioned purposes.",
    privacy_2_2_6: "For any other purposes which we may have notified you prior to, or at the time of, obtaining your consent.",
    privacy_2_3:
      "The provision of your name, family name, contact number, gender, date of birth, country code and email address are mandatory so that we can contact you and fulfil any of your order(s): if any required data is not provided, then we will not be able to fulfil your order(s). All other provision of your information is optional.",
    privacy_2_4:
      "Additionally, through the Website, we may gather certain aggregated and anonymous information about the use of the Website, such as how frequently certain areas of the Website are visited, including through the use of cookies, web beacons, Google Analytics and other technologies.",
    privacy_2_5: "We may use cookies, web beacons, Google Analytics and other technologies for the following purposes:",
    privacy_2_5_1: "to identify the causes of problems arising at web servers and to resolve these problems;",
    privacy_2_5_2: "to improve the contents of the Website;",
    privacy_2_5_3:
      "to customize the content of the Website and our emails to suit your individual interests or purposes and selectively display best-matched pages to you, including tracking your past purchases in order to provide you with new services or special promotions;",
    privacy_2_5_4: "to utilize your browsing history on the Website for demographic research; and",
    privacy_2_5_5: "to obtain statistic data of web visitors and aggregated website visitation statistics which are anonymous in nature.",
    privacy_2_6:
      "You may reject our use of cookies by configuring your browser to disable the use of cookies. However, you may no longer be able to receive services or personalized features of the Website which rely on the use of cookies.",
    privacy_3: "3. SENDING OF EMAILS TO MEMBERS",
    privacy_3_1:
      "Genuine emails from Imperial Treasure always will come from an address ending @imperialtreasure.com. Do not open any attachments or click any links from suspicious emails. If you wish to report a suspicious email claiming to be from Imperial Treasure that you believe is a forgery, you may email to sg.marketing@imperialtreasure.com",
    privacy_4: "4. WITHDRAWING CONSENT OR OTHERWISE OBJECTING TO DIRECT MARKETING",
    privacy_4_1:
      "Wherever we rely on your consent, you will always be able to withdraw that consent, although we may have other legal grounds for processing your personal data for other purposes, such as those set out above. You have an absolute right to opt-out of direct marketing, or profiling we carry out for direct marketing, at any time. You can do this by following the instructions in the communication where it is an electronic message, or by contacting us using the details set out below.",
    privacy_4_2:
      'Where you have provided us with your consent, Imperial Treasure, our affiliates, related entities, and third parties acting on our behalf ("Business Partners"), may collect, use and disclose your personal data, which you have provided at the Member\'s Portal, for providing marketing material that you have agreed to receive, in accordance with the Personal Data Protection Act 2012 and our Privacy Notice. Please note that marketing material may be sent directly by our Business Partners, and we may disclose your personal data to them for that purpose.',
    privacy_5: "5. DISCLOSURES OF PERSONAL DATA TO THIRD PARTIES",
    privacy_5_1:
      "In order to provide services to you and for the purposes listed at “2 Collection and Use of Personal Data” above, we may disclose and/or transfer your personal data out of Singapore, as described in this Privacy Notice:",
    privacy_5_1_1:
      "We may share your personal data with our affiliated companies for the purposes of fulfilling a contract with you, providing customer services and membership services, and facilitating your registration as a user of the Website:",
    privacy_5_1_2:
      "We may permit certain trusted third parties to access your personal data in connection with their performance of services to maintain and operate the Website and certain features on the Website. For example, we may use third parties to host the Website; operate various features available on the Website; send emails; analyze data; provide search results and links and assist in fulfilling your orders and may share personal data or other information with our parent, subsidiaries, divisions, and affiliates.",
    privacy_5_1_3:
      "We may employ a third-party service to collect the above-mentioned information, including personal data, and use their tracking services on our behalf. However, this information is strictly confidential and will not be shared with others.",
    privacy_5_1_4:
      "We may disclose personal data as an asset in connection with a proposed or actual merger or sale (including any disclosures made as part of an insolvency or bankruptcy proceeding) involving all or part of our business or as part of a corporate reorganization, stock sale or other change in control.",
    privacy_5_1_5:
      "We reserve the right to disclose information, including personal data, in order to comply with a subpoena, court order, administrative or governmental order, or any other requirement of law, or when we, in our sole discretion, believe it is necessary in order to protect our rights or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk reduction. We may also disclose or transfer personal data as otherwise permitted or required by law; and",
    privacy_5_1_6: "We may disclose aggregated and anonymized data to third parties.",
    privacy_5_2:
      "Your personal data may be transferred out of Singapore from time to time. Such transfers will be carried out in accordance with the Singapore Personal Data Protection Act 2012.",
    privacy_6: "6. HOW WE PROTECT YOUR INFORMATION",
    privacy_6_1:
      "We are committed to protecting the information we receive from you. We adopt reasonable physical, technical and administrative practices to help protect the confidentiality, security and integrity of data, including personal data, stored on our system. We believe the measures we have implemented reduce the likelihood of security problems to a level appropriate to the type of data involved. Although we take reasonable steps to protect the information we receive from you, no computer system is completely secure, and we cannot be held liable for any loss you may suffer for unauthorized access or loss of any data provided to the Website.",
    privacy_7: "7. LINKS TO THIRD PARTY SITES",
    privacy_7_1:
      "The Website may contain links to third party websites and microsites, including third party payment gateways, whose privacy practices may differ from those of ours. If you submit personal data, including payment information, to any of those websites, that personal data is not subject to this Privacy Notice and will be subject to the third party's privacy practices instead.",
    privacy_7_2:
      "We therefore encourage you to review the privacy statement of any website you visit. By clicking on or activating such links and leaving the Website, we can no longer exercise control over any data or information which you give to any other entity after leaving the Website, and any access to such other websites is entirely at your own risk.",
    privacy_8: "8. WHAT RIGHTS DO I HAVE",
    privacy_8_1:
      "You have the right to ask us for a copy of your personal data; to access or correct; and to obtain the personal data you provide to us for a contract or with your consent in a structured, machine readable format, and to ask us to share (port) this data to another data controller.",
    privacy_8_2:
      "These rights may be limited, for example if fulfilling your request would reveal personal data about another person or where they would infringe the rights of a third party (including our rights). Relevant exemptions are included in the applicable laws and regulations. We will inform you of relevant exemptions we rely upon when responding to any request you make.",
    privacy_8_3:
      "To exercise any of these rights, or to obtain other information, such as a copy of a legitimate interests balancing test, you can get in touch with us – by contacting our Data.",
    privacy_8_4:
      'Protection Officer (See "11. Contact us" below).  To access and correct your personal data, you can do so by signing into your user account and updating your personal data. We will only disclose personal data in accordance with an access request to the individual who registered his/her personal data with the Website, and only after confirming that the person requesting access is indeed the registered party.',
    privacy_8_5: "You may also contact our Data Protection Officer to withdraw your consent to our use of your personal data.",
    privacy_8_6: "Please allow thirty (30) days for your request to be processed.",
    privacy_9: "9. RETENTION OF INFORMATION",
    privacy_9_1: "Where we process registration data, we do this for as long as you are an active user of the Website and for six (6) years after this.",
    privacy_9_2:
      "Where we process personal data for marketing purposes or with your consent, we will process such data until you ask us to stop and for a short period after this (to allow us to implement your requests). We also keep a record of the fact that you have asked us not to send you direct marketing or not to process your data so that we can respect your request in the future.",
    privacy_9_3: "Where we process personal data in connection with performing a contract, we keep such data for six (6) years from your last interaction with us",
    privacy_9_4:
      "If you withdraw your consent to our collection, use or disclosure of your personal data, we may not be able to provide you with services that require such personal data. Please also note that your personal data may still be retained on our database to the extent permitted by law.",
    privacy_10: "10. PRIVACY NOTICE CHANGES",
    privacy_10_1:
      "If we decide to change our Privacy Notice for the Website, we will post the revised Privacy Notice here and, if appropriate, notify you by email. Please check back frequently to see any updates or changes to our Privacy Notice.",
    privacy_11: "12. CONTACT US",
    privacy_11_1: "If you have any questions about this Privacy Notice, please contact our Data Protection Officer at DPO@imperialtreasure.com.",
  },
  outlets: {
    outlets: "餐厅",
    restaurant_name: "餐厅名称",
    address: "住址",
    contact: "联系",
    email: "电子邮件",
    outlets_1_1: "御宝轩",
    outlets_1_2: "滨海湾金沙, 购物商城, #02-04,",
    outlets_1_3: "海湾舫道 8号, 新加坡 018955",
    outlets_1_4: "(+65) 6688 7788",
    outlets_1_5: "mbs@imperialtreasure.com",

    outlets_2_1: "御宝至尊烤鸭店",
    outlets_2_2: "百利宫, #05-42,",
    outlets_2_3: "乌节路 290号, 新加坡 238859",
    outlets_2_4: "(+65) 6732 7838",
    outlets_2_5: "paragon@imperialtreasure.com",

    outlets_3_1: "御宝至尊烤鸭店",
    outlets_3_2: "亚洲广场 1座, #02-08,",
    outlets_3_3: "滨海景 8号, 新加坡 018960",
    outlets_3_4: "(+65) 6636 1868",
    outlets_3_5: "asq@imperialtreasure.com",

    outlets_4_1: "御宝阁潮州酒家",
    outlets_4_2: "爱雍·乌节, #03-05,",
    outlets_4_3: "乌节路, 新加坡 238801",
    outlets_4_4: "(+65) 6736 2118",
    outlets_4_5: "fineteochew_ion@imperialtreasure.com",

    outlets_5_1: "御宝阁潮州酒家",
    outlets_5_2: "滨海湾金沙, 购物商城, #01-26,",
    outlets_5_3: "海湾舫道 2号, 新加坡 018972",
    outlets_5_4: "(+65) 6688 7333",
    outlets_5_5: "fineteochew_mbs@imperialtreasure.com",

    outlets_6_1: "御园",
    outlets_6_2: "高岛屋购物中心, #04-22,",
    outlets_6_3: "乌节路 391号, 新加坡 238872",
    outlets_6_4: "(+65) 6836 6909",
    outlets_6_5: "sh_nac4@imperialtreasure.com",

    outlets_7_1: "御宝轩",
    outlets_7_2: "大世界, #02-111,",
    outlets_7_3: "金声路, 新加坡 237994",
    outlets_7_4: "(+65) 6732 2232",
    outlets_7_5: "cc_gwc@imperialtreasure.com",

    outlets_8_1: "Imperial Treasure Bar + Grill",
    outlets_8_2: "暂时停止营业",
    outlets_8_3: "大世界, #02-111,",
    outlets_8_4: "金声路, 新加坡 237994",
    outlets_8_5: "(By Reservation only)",
    outlets_8_6: "(+65) 6732 2232",
    outlets_8_7: "bar_grill_gw@imperialtreasure.com",

    outlets_9_1: "御宝火锅店",
    outlets_9_2: "爱雍·乌节, #04-12A,",
    outlets_9_3: "乌节路, 新加坡 238801",
    outlets_9_4: "(+65) 6636 9339",
    outlets_9_5: "ion_steamboat@imperialtreasure.com",

    outlets_10_1: "御宝火锅店",
    outlets_10_2: "大世界, #01-140,",
    outlets_10_3: "金声路, 新加坡 237994",
    outlets_10_4: "(+65) 6235 6008",
    outlets_10_5: "steamboat_gwc@imperialtreasure.com",

    outlets_11_1: "御宝火锅店",
    outlets_11_2: "111 索美赛, #02-14,",
    outlets_11_3: "111索美赛路, 新加坡 238164",
    outlets_11_4: "(+65) 6235 3882",
    outlets_11_5: "steamboat_111@imperialtreasure.com",

    outlets_12_1: "宝一点心",
    outlets_12_2: "百利宫, #B1-08,",
    outlets_12_3: "乌节路 290号, 新加坡 238859",
    outlets_12_4: "(+65) 6262 3662",
    outlets_12_5: "paragon_ydx@imperialtreasure.com",

    outlets_13_1: "宝一点心",
    outlets_13_2: "百汇广场, #02-14,",
    outlets_13_3: "马林百列路, 新加坡 449269",
    outlets_13_4: "(+65) 6247 9218",
    outlets_13_5: "parkway_ydx@imperialtreasure.com",

    outlets_14_1: "宝一点心",
    outlets_14_2: "来福士城, #B1-37,",
    outlets_14_3: "北桥路 252号, 新加坡 179103",
    outlets_14_4: "(+65) 6262 1662",
    outlets_14_5: "rafflescity_ydx@imperialtreasure.com",

    outlets_15_1: "御宝至尊烤鸭店",
    outlets_15_2: "星耀樟宜机场, #01-219,",
    outlets_15_3: "机场大道78号, 新加坡 819666",
    outlets_15_4: "(+65) 6908 6689",
    outlets_15_5: "spd_jewel@imperialtreasure.com",

    outlets_16_1: "宝一点心",
    outlets_16_2: "星耀樟宜机场, #01-221,",
    outlets_16_3: "机场大道78号, 新加坡 819666",
    outlets_16_4: "(+65) 6908 3668",
    outlets_16_5: "jewel_ydx@imperialtreasure.com",

    outlets_17_1: "御宝淮扬料理",
    outlets_17_2: "爱雍·乌节 , #04-12A,",
    outlets_17_3: "乌节路, 新加坡 238801",
    outlets_17_4: "(+65) 6636 9339",
    outlets_17_5: "huaiyang_ion@imperialtreasure.com",
  },
  privilege: {
    title: "特权",
    privilege_effective: "- 自2025年4月1日起 -",
    subtitle: "参与御宝奖励计划，尊享无限美味与特权",
    privilege: "会员礼遇",
    privelege_txt_new: "在国内外任何<link>御宝分店<link>，每消费满 10 新元# 即可赚取 i$1。\n累积 i$50 即可获得 50 新元电子礼券,可在新加坡任何参与的御宝分店使用。",
    privelege_txt: "在参与分店每消费S$10",
    privelege_txt2: "，即可赚取i$1。\n累积i$50可兑换S$50电子礼劵，仅在参与分店使用。",
    silver: "银",
    silver_signup: "38 新元注册",
    silver_1: "一张 50 新元注册电子礼券 (有效期为 3 个月)",
    silver_2: "一张 100 新元生日电子礼券 (单张消费满 280 新元, 不包括服务费和消费税) [礼券分发日期为月初一(01)号, 若生日当月注册, 礼券将在明年分发。 仅在会员的生日月份内有效使用。每个会员期仅限分发一张]",
    silver_3: "两张火锅极品电子礼券 (日本和牛以及老虎斑, 各一份; 有效期为 12 个月, 仅限堂食, 适用于御宝火锅店)",
    silver_renewal: "20 新元更新 (从第二年更新会员身份起)",
    silver_4: "一张 28 新元更新电子礼券 (有效期为 3 个月)",
    silver_5: "一张 100 新元生日电子礼券  (单张消费满 280 新元, 不包括服务费和消费税) [礼券分发日期为月初一(01)号, 若生日当月更新, 礼券将在明年分发。 仅在会员的生日月份内有效使用。每个会员期仅限分发一张]",
    silver_6: "三张 30 新元火锅汤底电子礼券 (有效期为 12 个月, 仅限堂食, 适用于御宝火锅店)",
    gold: "金",
    gold_1: "凡在会员有效期内消费满 10,888 新元#, 便可成为金会员。",
    gold_2: "享有以下礼遇：",
    gold_3: "免费更新会员身份",
    gold_4: "一张 500 新元奖励电子礼券",
    gold_5: "九张免除开瓶电子礼券",
    gold_6: "无限次火锅汤底电子礼券",
    gold_7: "敬送礼品：\n北京式烤鸭一只 \n红酒一瓶 \n新年糕点一份 (季节性) \n端午粽子一份 (季节性) \n中秋月饼一盒 (季节性)",
    diamond: "钻石",
    diamond_1: "凡在会员有效期内消费满 S$28,888#, 便可成为钻石会员。",
    diamond_2: "享有金会员*礼遇，以及以下礼遇：",
    diamond_3: "一张 1,500 新元奖励电子礼券",
    diamond_4: "无限次免除开瓶电子礼券",
    diamond_5: "敬送礼品：\n烤乳猪一只 \n香槟一瓶 \n新年糕点一份 (季节性) \n端午粽子一份 (季节性) \n中秋月饼一盒 (季节性)",
    remarks: "# 不包括服务费，消费税，电子礼券兑换，购买或使用御宝礼券和季节食品。\n*每位会员在每个会员周期内仅可获得一 (01) 套金会员礼遇。",
    sign_up_now: "立即注册",
  },
  resetPassword: {
    title: "忘记密码",
    reset_password: "重置密码",
    email: "*请输入电子邮箱",
    email_input: "请输入电子邮箱",
    email_errMsg: "请输入有效的电子邮箱",
    mobile: "*请输入手机号码",
    mobile_errMsg: "请输入有效电话",
    new_password: "*新密码",
    new_password_errMsg1: "请输入密码",
    new_password_errMsg2: "密码必须至少包含8个字母数字字符，并且至少包含一个大写字母和一个特殊字符（?!@$%&*）",
    confirm_password: "*确认新密码",
    confirm_password_errMsg: "请确认密码",
    reset_password2: "前去重置密码",
    has_been_reset: "密码已重置！",
    login: "登录",
  },
  forgetPassword: {
    title: "忘记密码",
    reset_password: "重置密码",
    forgot_password: "忘记密码？",
    reset_pw_method: "请选择密码重置方式",
    by_mobile: "通过手机",
    by_email: "通过电子邮件",
    email: "*请输入电子邮箱",
    email_input: "请输入电子邮箱",
    email_errMsg: "请输入有效的电子邮箱",
    mobile: "*请输入手机号码",
    mobile_errMsg: "请输入有效电话",
    reset_password2: "前去重置密码",
    reset_password_sent_email: "重置密码链接已发送到您的电子邮箱。请注意查收！",
    reset_password_sent_mobile: "重置密码链接已发送到您的手机短讯。请注意查收！",
    next: "下一步",
    back: "上一步",
  },
  updateEmail: {
    title: "更新电子邮箱",
    member_profile: "会员档案",
    updateEmail: "更新电子邮箱",
    new_email: "*新电子邮箱",
    sent: "发送",
    send_token: "发送验证码",
    email_errMsg1: "请输入有效的电子邮件",
    email_errMsg2: "电子邮件已注册",
    token: "*验证码",
    token_error: "验证码错误",
    update: "更新",
    update_success: "更新成功！",
    done: "完成",
    Msg1: "手机和电子邮箱都已使用",
    Msg2: "电子邮箱已使用",
  },
  updateMobile: {
    title: "修改手机号码",
    member_profile: "会员档案",
    updateMobile: "修改手机号码",
    new_mobile: "*新手机号码",
    sent: "发送",
    send_token: "发送验证码",
    mobile_errMsg1: "请输入有效电话",
    mobile_errMsg2: "手机已注册",
    token: "*验证码",
    token_error: "验证码错误",
    update: "更新",
    update_success: "更新成功！",
    done: "完成",
    Msg1: "手机和电子邮件都已使用",
    Msg2: "电子邮件已使用",
  },
  coupon: {
    title: "礼劵",
    member_profile: "会员档案",
    coupon: "礼劵",
    birthdayNote: "您的生日电子礼券只会在您的生日月份内显示。",
    active: "有效的",
    redeemed: "已兑换",
    expired: "过期",
    type_of_vouchers_opt1: "请选择礼劵类型",
    type_of_vouchers_opt2: "类型A",
    type_of_vouchers_opt3: "类型B",
    type_of_vouchers_opt4: "类型C",
    type_of_vouchers_opt5: "类型D",
    expiry_date: "到期日期：",
    redeemed_store: "赎回商店：",
    vaild_date: "有效日期",
    description: "说明",
    vouchers_code: "礼劵代码",
    done: "完成",
    no_coupon_msg: "没有可用礼劵",
  },
  editProfile: {
    title: "编辑个人资料",
    member_profile: "会员档案",
    editProfile: "编辑个人资料",
    general: "账户资料",
    security: "安全信息",
    subscriptions: "信息订阅",
    salutation: "*称谓",
    salutation_opt1: "请选择您的称谓",
    salutation_opt2: "先生",
    salutation_opt3: "太太",
    salutation_opt4: "女士",
    salutation_opt5: "小姐",
    salutation_opt6: "博士",
    salutation_errMsg: "请选择称谓",
    gender: "*性别",
    gender_opt1: "请选择您的性别",
    gender_opt2: "男性",
    gender_opt3: "女性",
    gender_errMsg: "请选择性别",
    first_name: "*名字",
    first_name_errMsg: "请输入名字",
    last_name: "*姓氏",
    last_name_errMsg: "请输入姓氏",
    birth: "*出生日期",
    mobile: "*手机号码",
    mobile_change: "修改",
    email: "*电子邮件",
    email_change: "修改",
    language: "*语言选项",
    language_opt1: "请选择您的语言选项",
    language_opt2: "English",
    language_opt3: "简体中文",
    update: "更新",
    previous_password: "*旧密码",
    previous_password_errMsg: "旧密码密码不正确",
    new_password: "*新密码",
    new_password_errMsg1: "请输入密码",
    new_password_errMsg2: "密码必须至少包含8个字母数字字符，并且至少包含一个大写字母和一个特殊字符（?!@$%&*）",
    confirm_password: "*确认密码",
    confirm_password_errMsg: "请确认密码",
    subscriptions_txt1: "通过勾选以下的方框，我同意御宝餐饮集团有限公司及其子公司（“ITRG”）收集、使用、储存或披露与我的御宝会员身份相关的个人信息（“个人信息”），根据<link>隐私声明<link>，以便ITRG将我的个人信息共享至其附属公司、关联公司或其他第三方，从而使我能够享受御宝俱乐部会员资格的权益，包括源于ITRG与新加坡内外服务提供商之间关系而产生的权益。",
    subscriptions_txt2: "我希望收到来自ITRG的营销/推广信息与材料。",
    by_email: "通过电子邮件",
    by_mobile: "通过电话",
    update_success: "更新成功！",
    done: "完成",
    password_update_success: "密码修改成功！",
  },
  profile: {
    title: "会员",
    logout: "登出",
    click_to_renew: "单击以更新​",
    activation_now: "立即激活",
    menber_id: "会员号码:",
    member: "会员",
    active: "有效的",
    expired: "过期",
    salutation: "称谓：",
    mobile: "手机号码：",
    birth: "出生日期：",
    password: "密码：",
    ellipsis: "········",
    change: "更改",
    gender: "性别：",
    email: "电子邮件：",
    cycle_start_date: "等级开始日期：",
    first_joined_date: "首次加入日期：",
    optin_email: "选择电子邮件：",
    optin_sms: "选择短信：",
    cycle_expiry_date: "周期到期日期：",
    language_preference: "语言选项：",
    spend: "S$ 消费",
    expires_on: "  到期时间",
    spend_required: "还需净消费",
    to_upgrade_to: "升级为",
    diamond_membership: "钻石卡会员",
    nett_spend_required: "还需净消费維持为",
    nett: "净",
    to_maintain_to: "維持为",
    silver_member: "銀卡会员",
    gold_member: "金卡会员",
    diamond_member: "钻石卡会员",
    total_nett_spend: "总净消费金额",
    current_membership: "在当前会员年份*",
    i_dollar: "i-Dollar",
    last_update: "  最新一次更新：",
    i_dollar_earned: "賺取的 i-Dollar",
    i_dollar_converted: "已兌換的 i-Dollar",
    i_dollar_balance: "剩余的 i-Dollar",
    i_dollar_automatically: "$50 i-Dollars 将自动转换为50元的电子礼券",
    vouchers: "礼劵",
    currently_active: "当前有效",
    cash_vouchers: "现金礼劵",
    food_vouchers: "食品/促销券",
    click_to_view: "单击查看",
    transaction: "交易记录",
    this_month: "当月记录",
    last_3_months: "最近3个月",
    last_12_months: "过去12个月",
    transaction_details: "交易明细",
    transaction_date: "交易日期",
    transaction_time: "交易时间",
    outlet: "餐厅",
    receipt: "单据编码",
    payment_method: "付款方式",
    receipt_details: "单据明细",
    zero: ".0000",
    i_dollars_eared: "i-DOLLARS 赚取:",
    total: "总额：",
    done: "完成",
    thankyou_msg: "谢谢你选择我们的餐厅!",
    activate_membership: "激活会员资格",
    activate_membership_1: "只需 20 新元即可激活您的会员身份享受以下礼遇*：",
    activate_membership_2: "每消费 10 新元，即可赚取 i$1。累积 i$50 可兑换 50 新元电子礼券",
    activate_membership_3: "1 张 28 新元更新电子礼券 (有效期为 3 个月)",
    activate_membership_4: "1 张 100 新元生日电子礼券（仅限生日当月，且单笔有效消费满 280 新元时可使用）",
    activate_membership_5: "3 张 30新元火锅汤底 (有效期为 12 个月)",
    activate_membership_7: "率先收到最新信息和私人活动专属邀请！",
    renew_membership: "更新会员资格",
    renew_membership_1: "只需 20 新元即可更新您的会员身份享受以下礼遇*：",
    renew_membership_2: "每消费 10 新元，即可赚取 i$1。累积 i$50 可兑换 50 新元电子礼券",
    renew_membership_3: "1 张 28 新元更新电子礼券 (有效期为 3 个月)",
    renew_membership_4: "1 张 100 新元生日电子礼券（仅限生日当月，且单笔有效消费满 280 新元时可使用）",
    renew_membership_5: "3 张 30新元火锅汤底 (有效期为 12 个月)",
    renew_membership_6: "率先收到最新信息和私人活动专属邀请！",
    confirm_to_renew: "确认续订",
    confirm_to_activate: "确认激活",
    change_password: "修改密码",
    previous_password: "*旧密码",
    previous_password_errMsg: "旧密码不正确",
    new_password: "*新密码",
    new_password_errMsg1: "请输入并确认密码",
    new_password_errMsg2: "密码必须至少包含8个字母数字字符，并且至少包含一个大写字母和一个特殊字符（?!@$%&*）​",
    confirm_password: "*确认密码",
    close: "X 关闭",
    member_activated: "会员已激活",
    password_updated: "密码修改成功！",
    friend_referral: "推荐朋友",
    view_more: "查看更多",
    add_friend: "添加",
    silver_tier: "銀卡",
    gold_tier: "金卡",
    diamond_tier: "钻石卡",
    spend_date_format: "年过期",
  },
  friendReferral: {
    title: "推荐朋友",
    note1: "推荐成功后，推荐人将获得 S$10 电子礼券。",
    note2: "此外，被推荐注册的会员将获得额外的电子优惠券，以 S$78 （原价 S$178）的价格购买招牌北京烤鸭。",
    email: "电子邮箱",
    referee_email: "推荐朋友电子邮箱",
    emailAddress_errMsg: "请输入有效的电子邮箱",
    mobile: "电话号码",
    referee_mobile: "推荐朋友电话号码",
    mobile_errMsg: "请输入有效的电话号码",
    mobile_errMsg2: "手机号码已注册",
    name: "姓名",
    referee_name: "推荐朋友姓名",
    name_errMsg: "请输入推荐朋友姓名",
    referral_sucMsg: "你已成功推荐朋友!",
    referral_errMsg: "推荐朋友已为现有御宝注册会员。",
    submit: "立即推荐",
    tnc: "附带条款"
  },
  transactionDetails: {
    transaction_date: "交易日期",
    outlet: "餐厅",
    receipt_no: "单据编码",
    amount_spend: "消费金额",
    amount_qualified_for_i_dollar: "符合 i-Dollar 金额",
    i_dollars: "i-Dollars",
    selected_start_date: "选定开始日期",
    selected_end_date: "选定结束日期",
    no_tranMsg: "所选期间无交易记录。",
    action: "动作",
    apply: "应用",
    view: "查看",
  },
  contactUs: {
    title: "联系我们",
    title_message: "請填寫並在下面提交您的反饋，我們將在3-5個工作天内回覆您。",
    firstname: "名字",
    firstname_errMsg: "请输入您的名字",
    lastname: "姓氏",
    lastname_errMsg: "请输入您的姓氏",
    emailAddress: "电子邮箱",
    emailAddress_errMsg: "请输入有效的电子邮箱",
    mobile: "手机号码",
    mobile_errMsg: "请输入有效电话",
    type: "类型",
    type_opt1: "请输入您的反馈信息类型 :",
    type_opt2: "联络我们",
    type_opt3: "查询",
    type_opt4: "反馈",
    type_opt5: "电子报",
    type_errMsg: "请输入您的反馈信息类型",
    message: "信息",
    message_errMsg: "请输入您的反饋信息",
    captcha: "验证码",
    captcha_errMsg: "验证码不正确",
    feedback_sucMsg: "感谢您与我们联系并向我们提供宝贵的反馈。 \n我们会尽快回复您。",
  },
  mobileApp: {
    title: "御宝手机应用程序",
    desc_1: "享美食，赚奖励",
    desc_2: "立即下载御宝手机应用程序！"
  },
  languageSetting: {
    title: "语言设置",
    setting: "语言选项",
    language_preference: "语言选项",
    language_preference_opt1: "请选择您的语言选项",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_errMsg: "请选择语言选项",
    submit: "提交",
  },
};